<template>
  <el-button :class="{ 'no-border': noBorder }"
             :size="size"
             class="fa-button"
             v-on="$listeners"
             :disabled="!isAdmin && !disableEscaped"
  >
    <fa-icon v-if="icon"
             :icon="icon"
             fix-width
    />
    <slot/>
  </el-button>
</template>

<script>
  import { Button } from 'element-ui';
  import { mapActions, mapGetters } from "vuex";  
  export default {
    name: 'FaButton',
    extends: Button,
    computed: {
      ...mapGetters({
        isAdmin : "user/isAdmin",
      }),
    },
    props: {
      size: {
        type: String,
        default: '',
        validator(value) {
          return ['', 'medium', 'small', 'mini'].indexOf(value) > -1;
        },
      },
      icon: {
        type: [String, Array],
        required: false,
      },
      noBorder: {
        type: Boolean,
        default: false,
      },
      disableEscaped :{
        required: false,
        type: Boolean,
        default: false,
      }
    },
  };
</script>

<style scoped>
  .no-border {
    border: 0;
  }
</style>
