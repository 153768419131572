<template>
  <div
    id="data-table"
    class="data-tables-wrapper"
  >
    <div
      v-if="showScrollArrow"
      class="scroll-left-container"
      @click="scrollLeft"
    >
      <img
        src="/asserts/plan-mission/table-next-btn.svg"
        alt="next-btn"
      >
    </div>

    <div
      v-else
      class="scroll-right-container"
      @click="scrollRight"
    >
      <img
        src="/asserts/plan-mission/table-previous-btn.svg"
        alt="previous-btn"
      >
    </div>

    <data-tables-server
      ref="bodyWrapper"
      :data="$props.data"
      :total="$props.total"
      :current-page="$props.currentPage"
      :loading="$props.loading"
      :table-props="tableProps"
      :filters="filters"
      :pagination-props="paginationProps"
      :page-size="pageSize"
      :layout="layout"
      class="app-datatable"
      v-on="$listeners"
    >
      <div
        v-if="showToolBar"
        slot="tool"
        class="app-datatable__toolbar"
      >
        <el-input
          v-show="searchBarDisplay"
          v-model="searchText"
          :placeholder="$t('button.search')"
          class="app-datatable__search"
          clearable
          @keyup.enter.native="searchData"
          @clear="searchData"
        >
          <fa-button
            v-show="searchBarDisplay"
            slot="append"
            icon="search"
            @click="searchData"
          />
        </el-input>
        <json-excel
          v-show="excelDisplay"
          :name="fileName"
          :data="$props.data"
          :fields="exportfields"
          class="app-datatable__json-excel"
          @type-change="handleTypeChanged"
        >
          {{ $t('button.export') }} {{ type }}
        </json-excel>
      </div>
      <slot />
    </data-tables-server>
  </div>
</template>

<script>
import { DataTablesServer } from 'vue-data-tables';
import FaButton from '@/components/FaButton.vue';

export default {
  name: 'DataTable',
  components: {
    DataTablesServer,
    FaButton,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fileName: {
      type: String,
      default: 'table_data',
    },
    tableProps: {
      type: Object,
      default() {
        return {
          border: false,
          height: '100%',
          'row-class-name': 'coretronic',
          'header-row-class-name': 'coretronic table-header',

        };
      },
    },
    config: {
      type: Object,
      required: true,
    },
    paginationProps: {
      type: Object,
      default() {
        return {
          background: true,
          pageSizes: [10, 20, 50, 100],
        };
      },
    },
    showToolBar: {
      type: Boolean,
      default: true,
    },
    excelDisplay: {
      type: Boolean,
      default: true,
    },
    searchBarDisplay: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    layout: {
      type: String,
      default: 'table',
    },
  },
  data() {
    return {
      type: 'Excel',
      searchText: '',
      filters: [],
      showScrollArrow: true,
      bodyWrapper: null,
    };
  },
  computed: {
    parentProps() {
      return this.$props;
    },
    exportfields() {
      const temp = {};
      this.$props.config.tableColumnProps.forEach((item) => {
        temp[item.label] = item.prop;
      });
      return temp;
    },
    searchableProps() {
      return this.$props.config.tableColumnProps.reduce((result, prop) => {
        if (prop.searchable === true) {
          result.push(prop.prop.toString());
        }
        return result;
      }, []);
    },
    fields() {
      return this.config.tableColumnProps;
    },
  },
  mounted() {
    this.bodyWrapper = this.$refs.bodyWrapper.$el.querySelector('.el-table__body-wrapper');
  },
  methods: {
    handleTypeChanged(type) {
      if (type === 'csv') {
        this.type = 'CSV';
      } else {
        this.type = 'Excel';
      }
    },
    searchData() {
      const vals = this.searchText === '' ? [] : [this.searchText];
      this.filters = [
        {
          props: this.searchableProps,
          vals,
        },
      ];
    },
    scrollLeft() {
      this.bodyWrapper.scrollTo({ left: 1500, behavior: 'smooth' });
      this.showScrollArrow = false;
    },
    scrollRight() {
      this.bodyWrapper.scrollTo({ left: -1500, behavior: 'smooth' });
      this.showScrollArrow = true;
    },
  },
};
</script>

<style lang="scss">
  .app-datatable {
    position: absolute;
    height: 90%;
    width: 100%;
    .app-datatable__toolbar {
      .app-datatable__search {
        max-width: 350px;
      }
    }

    .pagination-bar {
      margin-top: 20px;
    }
  }

  #data-table {
    div {
      word-break: keep-all;
    }
    .scroll-left-container, .scroll-right-container {
      cursor: pointer;
      position: absolute;
      top: 11px;
      right: 1px;
      z-index: 5;
      padding: 0px 8px;
      background-color: $light-gray-600;
      visibility: hidden;
      cursor: pointer;
      @media (max-width: 995px) {
        visibility: visible;
      }
    }

    .el-table__body-wrapper.is-scrolling-left,
    .el-table__body-wrapper.is-scrolling-right,
    .el-table__body-wrapper.is-scrolling-middle {
      overflow-x: hidden;
    }

    .el-table th, .el-table tr {
      height: 45px;
    }
  }

</style>
